<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between;">
      <button
        class="button button-fill"
        :class="{disabled: activeTab==0}"
        @click="tabDisabled(($refs['main-tabs'].activeIndex-1)+$refs['main-tabs'].buttons.length%$refs['main-tabs'].buttons.length)? null : $refs['main-tabs'].prevTab()"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{$t('back')}}</span>
      </button>
      <button
        class="button button-fill"
        @click="tabDisabled(($refs['main-tabs'].activeIndex+1)%$refs['main-tabs'].buttons.length)? null : $refs['main-tabs'].nextTab()"
        v-if="activeTab<2"
      >
        <span>{{$t('next')}}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
    </nav>
    <section>
      <article style="height:436px; width: 675px;">
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top:0px; left:0px; height:435px;"
          :buttons="[
            '1. Representante',
            '2. Liquidaciones',
            '3. Facturas'
          ]"
          :classButton="function(i){return {disabled: tabDisabled(i)}}"
          :styleButton="'width:33%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <fieldset
              style="position: relative; left: 80px; top: 80px; width: 480px; height: 200px"
              model="liquidacion_comision"
            >
              <legend>Seleccione representante</legend>
              <field
                name="representante_id"
                widget="m2o"
                labelProp="nombre"
                width="320px"
                style="top:47px; left:70px;"
              />
              <field
                name="liquidaciones"
                widget="checkbox"
                label="Liquidaciones"
                reverse
                inline-label
                style="top:70px; left:70px;"
              />
              <field
                name="notas_liquidaciones"
                widget="checkbox"
                label="Prop. de Liquidaciones"
                reverse
                inline-label
                style="top:85px; left:70px;"
                v-if="showWt"
              />
              <field
                name="desde"
                type="date"
                label="Desde"
                width="100px"
                inline-label
                style="top:86px; right:50px;"
              />
              <field
                name="hasta"
                type="date"
                label="Hasta"
                width="100px"
                inline-label
                style="top:106px; right:50px;"
              />
            </fieldset>
          </div>
          <div class="tab">
            <field
              ref="documentos_pendientes"
              name="documentos_pendientes"
              widget="handsontable"
              :height="360"
              :width="650"
              :minRows="16"
              style="top:30px; left:4px; width: 653px;"
              customprop="docs_customprop"
              :columns="[
                {name:'sel',header:'Sel.', widget: 'checkbox', help: 'Seleccionado'},
                {name:'tipo_doc',header:'T',readOnly:true, renderer:wtRenderer},
                {name:'ndoc',header:'NºDocumento',readOnly:true},
                {name:'representante_id',header:'Representante',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
                {name:'fecha',type:'date2',header:'Fecha',readOnly:true},
                {name:'total',header:'Total',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'divisa_id',header:'Divisa',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
              ]"
            />
            <span style="position:absolute; top:5px; left:5px;">
              <button name="sel_all_docs" v-tooltip="'Seleccionar todos'" @click="sellAllDocs">
                <fa-icon :icon="['far', 'check-square']" />
              </button>
              <button
                name="dessel_all_docs"
                v-tooltip="'Desseleccionar todos'"
                @click="desselAllDocs"
              >
                <fa-icon :icon="['far', 'square']" />
              </button>
            </span>
          </div>
          <div class="tab">
            <field
              ref="lineas_pendientes"
              name="lineas_pendientes"
              widget="handsontable"
              label="Líneas"
              :height="380"
              :width="650"
              :minRows="16"
              style="top:5px; left:4px; width: 653px;"
              customprop="lines_customprop"
              readonly
              :htSettings="{columnSorting: true}"
              :columns="[
                {name:'delegacion_id',header:'Del.', type:'m2o', primary:'codigo', readOnly: true},
                {name:'cliente_id',header:'Cliente', type:'m2o', primary:'codigo', readOnly: true},
                {name:'fecha',header:'Fecha', type:'date2', primary:'codigo', readOnly: true},
                {name:'total',header:'total',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'divisa_id',header:'Divisa', type:'m2o', primary:'codigo', readOnly: true},
                {name:'cambio',header:'Cambio',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'formapago_id',header:'F.Pago', type:'m2o', primary:'codigo', readOnly: true},
                {name:'calculo',header:'Cálculo',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'comision',header:'Comisión',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'tipo_cobro',header:'T.Cob.'},
                //penalizacion
                //cobrado
                {name:'porcentaje_cobrado',header:'%Cob.',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'liquidable',header:'Liquidable',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'liquidado',header:'Pagado',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'pendiente',header:'Pendiente',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
                {name:'liquidar',header:'Liquidar',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
var floatOrZero = function(val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function() {
    return {
      title: "Documentos de representante",
      dbAdapter: "liquidacion_comision",
      primary: "ndoc",
      mode: "new",
      showWt: false,
      original_documentos_pendientes: [],
      original_lineas_pendientes: [],
      activeTab: 0
      /*sequence: { name: "almacen" }*/
    };
  },
  methods: {
    wtRenderer: function(instance, td, row, col, prop, value, cellProperties) {
      var self = this;
      if (!self.$refs.documentos_pendientes.field) return;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      var wt = h.getSourceData()[row]["wt"];
      if (parseInt(wt)) {
        td.style.color = "black!important";
        //window.$(td).addClass("plum");
        td.style.background = "antiquewhite";
      }
      Handsontable.renderers.TextRenderer.apply(this, arguments);
    },
    docsSelected() {
      var self = this;
      return self.formData.documentos_pendientes
        ? self.formData.documentos_pendientes.filter(doc => parseInt(doc.sel))
        : [];
    },
    lineasSelected() {
      var self = this;
      return self.formData.lineas_pendientes
        ? self.formData.lineas_pendientes.filter(doc => parseInt(doc.sel))
        : [];
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          var p = Promise.resolve();
          var tts = {
            liquidaciones: {
              model: "liquidacion_comision",
              wt: 0,
              tipo_doc: "LIQUIDACIÓN"
            },
            notas_liquidaciones: {
              model: "liquidacion_comision",
              wt: 1,
              tipo_doc: "LIQUIDACIÓN"
            }
          };
          self.$set(self.formData, "documentos_pendientes", []);
          self.original_documentos_pendientes = [];
          for (let tt in tts) {
            if (self.formData[tt]) {
              p = p.then(
                _ =>
                  new Promise(function(resolve, reject) {
                    let filter = [
                      [
                        "representante_id",
                        "=",
                        self.formData.representante_id.codigo
                      ],
                      ["wt", "=", tts[tt].wt]
                    ];
                    if (self.formData.desde)
                      filter.push(["fecha", ">=", self.formData.desde]);
                    if (self.formData.hasta)
                      filter.push(["fecha", "<=", self.formData.hasta]);
                    window.DB.getList(tts[tt].model, {
                      filter,
                      fields: [
                        "ndoc",
                        "wt",
                        { name: "representante_id", fields: ["nombre"] },
                        "fecha",
                        "total",
                        { name: "divisa_id", fields: ["nombre"] },
                        "cambio",
                        {
                          name: "lineas",
                          fields: [
                            "ndoc",
                            { name: "delegacion_id", fields: ["name"] },
                            {
                              name: "cliente_id",
                              fields: ["nombre_comercial"]
                            },
                            "fecha",
                            "total",
                            { name: "divisa_id", fields: ["nombre"] },
                            "cambio",
                            { name: "formapago_id", fields: ["descripcion"] },
                            "calculo",
                            "penalizacion",
                            "comision",
                            "tipo_cobro",
                            "cobrado",
                            "porcentaje_cobrado",
                            "liquidable",
                            "liquidado",
                            "pendiente",
                            "liquidar"
                          ]
                        }
                      ]
                    })
                      .then(function(res) {
                        res.data.forEach(function(doc) {
                          doc.tipo_doc = tts[tt].tipo_doc;
                          self.original_documentos_pendientes.push(
                            JSON.parse(JSON.stringify(doc))
                          );
                          delete doc.lineas;
                          self.formData.documentos_pendientes.push(doc);
                        });
                        resolve();
                      })
                      .catch(function() {
                        reject();
                      });
                  })
              );
            }
          }
          p.then(function() {
            self.$forceUpdate();
          }).catch(function() {
            window.console.log("eeeeeer");
          });
          break;
        case 2:
          self.$set(self.formData, "lineas_pendientes", []);
          self.original_lineas_pendientes = [];
          self.formData.documentos_pendientes.forEach(function(doc, i) {
            if (!parseInt(doc.sel)) return;
            //TODO: origin
            self.original_documentos_pendientes[i].lineas.forEach(function(
              linea
            ) {
              linea.unidades_pendientes =
                parseInt(linea.unidades || 0) -
                parseInt(linea.unidades_facturadas || 0);
              linea.peso_pendiente =
                parseInt(linea.peso || 0) - parseInt(linea.peso_facturado || 0);
              linea.unidades = linea.unidades_pendientes;
              linea.peso = linea.peso_pendiente;
              self.formData.lineas_pendientes.push(linea);
              let linea2 = JSON.parse(JSON.stringify(linea));
              if (doc.tipo_doc == "PEDIDO") linea2.origin_pedido_id = linea2.id;
              else linea2.origin_albaran_id = linea2.id;
              delete linea2.id;
              delete linea2.nota;
              self.original_lineas_pendientes.push(linea2);
            });
          });
          self.$forceUpdate();
      }
    },
    sellAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 1, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    desselAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 0, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    sellAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 1, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    desselAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 0, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    tabDisabled(i) {
      var self = this;
      switch (i) {
        case 1:
          return (
            !self.formData.representante_id ||
            (!self.formData.liquidaciones && !self.formData.notas_liquidaciones)
          );
        case 2:
          return !self.docsSelected().length;
      }
    },
    onChange(arrProps, callback) {
      this.$on("change", function(prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    }
  },
  mounted() {
    var self = this;
    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function(
      e
    ) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new" && self.mode != "search")
          return;
        self.showWt = true;
        self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
        self.$emit("change", "wt", "edit");
        if (self.mode == "search") self.setFilter(self.getFilter());
      }
    });
  }
};
</script>
<style>
tr.wtdoc > td {
  background: #72dad7 !important;
  color: white !important;
}
</style>