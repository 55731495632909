var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav',{staticStyle:{"justify-content":"space-between"}},[_c('button',{staticClass:"button button-fill",class:{disabled: _vm.activeTab==0},on:{"click":function($event){_vm.tabDisabled((_vm.$refs['main-tabs'].activeIndex-1)+_vm.$refs['main-tabs'].buttons.length%_vm.$refs['main-tabs'].buttons.length)? null : _vm.$refs['main-tabs'].prevTab()}}},[_c('fa-icon',{attrs:{"icon":['fas', 'chevron-left']}}),_c('span',[_vm._v(_vm._s(_vm.$t('back')))])],1),(_vm.activeTab<2)?_c('button',{staticClass:"button button-fill",on:{"click":function($event){_vm.tabDisabled((_vm.$refs['main-tabs'].activeIndex+1)%_vm.$refs['main-tabs'].buttons.length)? null : _vm.$refs['main-tabs'].nextTab()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('next')))]),_c('fa-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1):_vm._e()]),_c('section',[_c('article',{staticStyle:{"height":"436px","width":"675px"}},[_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"0px","left":"0px","height":"435px"},attrs:{"wt":_vm.formData.wt,"buttons":[
          '1. Representante',
          '2. Liquidaciones',
          '3. Facturas'
        ],"classButton":function(i){return {disabled: _vm.tabDisabled(i)}},"styleButton":'width:33%;'},on:{"active":_vm.onActiveTab}},[_c('div',{staticClass:"tab"},[_c('fieldset',{staticStyle:{"position":"relative","left":"80px","top":"80px","width":"480px","height":"200px"},attrs:{"model":"liquidacion_comision"}},[_c('legend',[_vm._v("Seleccione representante")]),_c('field',{staticStyle:{"top":"47px","left":"70px"},attrs:{"name":"representante_id","widget":"m2o","labelProp":"nombre","width":"320px"}}),_c('field',{staticStyle:{"top":"70px","left":"70px"},attrs:{"name":"liquidaciones","widget":"checkbox","label":"Liquidaciones","reverse":"","inline-label":""}}),(_vm.showWt)?_c('field',{staticStyle:{"top":"85px","left":"70px"},attrs:{"name":"notas_liquidaciones","widget":"checkbox","label":"Prop. de Liquidaciones","reverse":"","inline-label":""}}):_vm._e(),_c('field',{staticStyle:{"top":"86px","right":"50px"},attrs:{"name":"desde","type":"date","label":"Desde","width":"100px","inline-label":""}}),_c('field',{staticStyle:{"top":"106px","right":"50px"},attrs:{"name":"hasta","type":"date","label":"Hasta","width":"100px","inline-label":""}})],1)]),_c('div',{staticClass:"tab"},[_c('field',{ref:"documentos_pendientes",staticStyle:{"top":"30px","left":"4px","width":"653px"},attrs:{"name":"documentos_pendientes","widget":"handsontable","height":360,"width":650,"minRows":16,"customprop":"docs_customprop","columns":[
              {name:'sel',header:'Sel.', widget: 'checkbox', help: 'Seleccionado'},
              {name:'tipo_doc',header:'T',readOnly:true, renderer:_vm.wtRenderer},
              {name:'ndoc',header:'NºDocumento',readOnly:true},
              {name:'representante_id',header:'Representante',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
              {name:'fecha',type:'date2',header:'Fecha',readOnly:true},
              {name:'total',header:'Total',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'divisa_id',header:'Divisa',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true} ]}}),_c('span',{staticStyle:{"position":"absolute","top":"5px","left":"5px"}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Seleccionar todos'),expression:"'Seleccionar todos'"}],attrs:{"name":"sel_all_docs"},on:{"click":_vm.sellAllDocs}},[_c('fa-icon',{attrs:{"icon":['far', 'check-square']}})],1),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Desseleccionar todos'),expression:"'Desseleccionar todos'"}],attrs:{"name":"dessel_all_docs"},on:{"click":_vm.desselAllDocs}},[_c('fa-icon',{attrs:{"icon":['far', 'square']}})],1)])],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"lineas_pendientes",staticStyle:{"top":"5px","left":"4px","width":"653px"},attrs:{"name":"lineas_pendientes","widget":"handsontable","label":"Líneas","height":380,"width":650,"minRows":16,"customprop":"lines_customprop","readonly":"","htSettings":{columnSorting: true},"columns":[
              {name:'delegacion_id',header:'Del.', type:'m2o', primary:'codigo', readOnly: true},
              {name:'cliente_id',header:'Cliente', type:'m2o', primary:'codigo', readOnly: true},
              {name:'fecha',header:'Fecha', type:'date2', primary:'codigo', readOnly: true},
              {name:'total',header:'total',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'divisa_id',header:'Divisa', type:'m2o', primary:'codigo', readOnly: true},
              {name:'cambio',header:'Cambio',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'formapago_id',header:'F.Pago', type:'m2o', primary:'codigo', readOnly: true},
              {name:'calculo',header:'Cálculo',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'comision',header:'Comisión',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'tipo_cobro',header:'T.Cob.'},
              //penalizacion
              //cobrado
              {name:'porcentaje_cobrado',header:'%Cob.',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'liquidable',header:'Liquidable',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'liquidado',header:'Pagado',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'pendiente',header:'Pendiente',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true},
              {name:'liquidar',header:'Liquidar',type:'numeric' ,numericFormat: { pattern: '0,0.00'}, readOnly: true} ]}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }